import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Header from '../../../components/header'
import DailyMenu from '../../../components/daily-menu'
import Section from '../../../components/section'
import Footer from '../../../components/footer'

const MobileMenuEng = ({data}) => {

    const breakfast = data.firestore.menu.breakfast
    const preLunch = data.firestore.menu.preLunch
    const drinks = data.firestore.menu.drinks

  return(
    <Layout>
        <div className='container grid-xs'>
            <Header />
            <div className="lang text-center mb-layout-2">
              <Link className='btn chip' to="/mobile-menu" >En Espanol</Link>
              <Link className='btn chip active' to="/en/mobile-menu" >In English</Link>
            </div>
            <DailyMenu lang='eng' />
            <div className="columns">
              <div className="column col-12">
                <div className="section">
                  <h3 className="text-center">{breakfast.title_eng}</h3>
                  <Section content={breakfast} lang='eng' scope='toast' />
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column col-12">
                <div className="section">
                  <h3 className="text-center">{preLunch.title_eng}</h3>
                  <Section content={preLunch} lang='eng' scope='plateDishes' />
                  <Section content={preLunch} lang='eng' scope='sandwiches' />
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column col-12">
                <div className="section">
                  <h3 className="text-center">{drinks.title_eng}</h3>
                  <Section content={drinks} lang='eng' scope='coffee' />
                  <Section content={drinks} lang='eng' scope='beer' />
                  <Section content={drinks} lang='eng' scope='soft' />
                </div>
              </div>
            </div>
        </div>
        <Footer />
    </Layout>
  )
}

export const Head = () => <Seo title="Menu" />

export default MobileMenuEng

export const menuQueryEng = graphql`
query MenuQueryEng {
    firestore(id: {eq: "a6edfe77-f020-58c7-b29a-dd040483980e"}) {
      menu {
        drinks {
          info_eng
          info_es
          title_eng
          title_es
          beer {
            title_eng
            title_es
            info_eng
            info_es
            menuItems {
              title_eng
              title_es
              price
            }
          }
          soft {
            title_eng
            title_es
            menuItems {
              title_eng
              title_es
              price
            }
          }
          coffee {
            title_eng
            title_es
            info_eng
            info_es
            menuItems {
              title_eng
              title_es
              price
            }
          }
        }
        breakfast {
          info_eng
          info_es
          title_eng
          title_es
          toast {
            info_eng
            info_es
            menuItems {
              price
              title_eng
              title_es
            }
            title_eng
            title_es
          }
        }
        preLunch {
          info_eng
          info_es
          plateDishes {
            info_eng
            info_es
            menuItems {
              info_eng
              info_es
              price
              title_eng
              title_es
            }
            title_eng
            title_es
          }
          sandwiches {
            menuItems {
              title_eng
              title_es
              price
            }
            title_eng
            title_es
          }
          title_eng
          title_es
        }
      }
    }
}  
`